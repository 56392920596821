<template>
  <div class="mt-2">
    <div class="reservation">
      <b-table :items="items" :fields="fields">
        <template v-slot:cell(total)="data">
          <div class="form-group text-right cell-price">
            &euro;
            {{
              (Math.round(data.item.price * data.item.amount * 100) / 100)
                .toFixed(2)
                .replace('.', ',')
            }}
          </div>
        </template>
      </b-table>
    </div>
    <div class="prices">
      <table class="table text-right">
        <tr>
          <td>
            <strong>Subtotaal:</strong>
          </td>
          <td>
            &euro;
            {{
              totalIncVat
                .toFixed(2)
                .toString()
                .replace('.', ',')
            }}
          </td>
        </tr>
        <tr v-if="adminCosts">
          <td>
            <strong>Administratiekosten:</strong>
          </td>

          <td width="100px">&euro; 10,00</td>
        </tr>
        <tr>
          <td>
            <strong>Totaalbedrag:</strong>
          </td>
          <td>
            &euro;
            {{
              cartCache.TotalIncVAT.toFixed(2)
                .toString()
                .replace('.', ',')
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cartCache: {
      type: Object,
      required: true
    },
    adminCosts: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      fields: [
        {
          key: 'item',
          label: 'Item'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'Totaal'
        }
      ]
    }
  },
  computed: {
    totalIncVat: function() {
      if (this.adminCosts) return this.cartCache.TotalIncVAT - 10.0
      return this.cartCache.TotalIncVAT
    }
  }
}
</script>

<style lang="scss" scoped>
.prices {
  padding-right: 0px;
  .table {
    background-color: transparent;
    tr,
    td {
      border: none !important;
      font-size: 18px;
      strong {
        font-family: 'DecimaPro-Bold';
      }
    }
  }
}
.cell-price {
  white-space: nowrap;
}
</style>
