<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Materiaal aanvragen" />
    <h1>Materiaal aanvragen</h1>
    <hr />
    <b-alert v-if="items.length < 1 && !loading" show variant="warning">
      <font-awesome-icon icon="exclamation-circle" />Opgelet: Er zijn geen
      artikelen aanwezig in je winkelmandje en kunt daarom geen nieuwe aanvraag
      maken.
    </b-alert>
    <b-form @submit.prevent="checkout">
      <b-row v-if="items.length > 0">
        <b-col lg="7" sm="12">
          <h2>Gegevens van de besteller</h2>
          <p>
            Kijk je persoonlijke gegevens hier na. Zijn deze niet meer
            up-to-date, pas ze dan
            <router-link
              :to="{ name: 'editSub', params: { username: user.UserName } }"
              class="pt-2"
              >hier</router-link
            >
            aan.
          </p>

          <confirmation-buyer-details :orderer="orderer" />

          <h2 class="mt-4">Informatie over het evenement</h2>
          <p>
            Om een correcte inschatting van de materialen te kunnen maken,
            vragen we je een aantal gegevens over je evenement. Een medewerker
            van de uitleendienst kan je evenement komen bezoeken om het correct
            gebruik van het materiaal te verifiëren. Maak je reclame voor je
            evenement en vermeld je sponsors, dan ben je verplicht om ook het
            logo van de provincie op te nemen. Het juiste logo kan je
            <a
              href="https://www.vlaamsbrabant.be/nl/over-de-provincie/huisstijl"
              target="_blank"
              >hier</a
            >
            terugvinden.
          </p>
          <b-card class="mt-4 mb-4">
            <b-row>
              <b-col cols="12" class="pb-3">
                <label
                  >Omschrijving van het evenement
                  <span class="text-danger">*</span></label
                >
                <b-form-textarea
                  id="eventDescription"
                  v-model="reserverationDetails.eventDescription"
                  required
                  rows="3"
                  maxlength="100"
                  max-rows="6"
                  @change="replaceSpecialCharacters('eventDescription')"
                ></b-form-textarea>
              </b-col>
              <b-col xs="12" md="6" class="pb-3">
                <label>
                  Startdatum evenement
                  <span class="text-danger">*</span>
                </label>
                <calendar
                  v-model="reserverationDetails.eventStartDate"
                  show-date-only
                  :format="format"
                  :clear-button="clear"
                  :min-date="cartCache.DateTimeBusinessStart"
                  :max-date="cartCache.DateTimeBusinessEnd"
                  :placeholder="placeholder"
                  :lang="lang"
                  class="w-100"
                  width="100%"
                ></calendar>
              </b-col>
              <b-col xs="12" md="6" class="pb-3">
                <label
                  >Startuur evenement <span class="text-danger">*</span></label
                >
                <b-form-select
                  v-model="reserverationDetails.eventStartHour"
                  :options="eventStartHourOptions"
                  required
                ></b-form-select>
              </b-col>
              <b-col cols="12" class="pb-3">
                <label>
                  Adres waar het evenement doorgaat
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="reserverationDetails.eventAdres"
                  required
                  maxlength="100"
                ></b-form-input>
              </b-col>
              <b-col cols="12" class="pb-3">
                <label>
                  Verwachte opkomst (aantal personen)
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="reserverationDetails.expectedRise"
                  type="text"
                  required
                  step="1"
                  :number="true"
                  max="99999"
                  :formatter="formatterToNumber"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="5"
                  @change="
                    handleChange({
                      value: $event,
                      name: 'expectedRise',
                      type: 'number'
                    })
                  "
                ></b-form-input>
              </b-col>
              <b-col v-if="paAttestRequired" cols="12" class="pb-3">
                <label>PA-attest opladen (indien noodzakelijk)</label>
                <br />
                <b-button variant="outline-primary" @click="chooseFiles()"
                  >Selecteer bestanden</b-button
                >
                <b-form-file
                  id="chooseFiles"
                  v-model="reserverationDetails.file"
                  size="sm"
                  hidden
                  plain
                ></b-form-file>
                <span v-if="paAttestRequired">
                  <i class="mt-3">
                    {{
                      reserverationDetails.file
                        ? reserverationDetails.file.name
                        : 'Geen bestanden geselecteerd...'
                    }}
                  </i>
                </span>
              </b-col>
              <b-col cols="12" class="pb-3">
                <label>Opmerkingen</label>

                <b-form-textarea
                  id="extraInfo"
                  v-model="reserverationDetails.extraInfo"
                  rows="3"
                  maxlength="1000"
                  max-rows="6"
                  @change="replaceSpecialCharacters('extraInfo')"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="5" sm="12">
          <h2>Overzicht</h2>
          <b-card>
            <div>
              <strong>Afhaallocatie</strong>
              <p>{{ cartCache.WarehouseID }}</p>
            </div>
            <div>
              <strong>Afhaaldatum</strong>
              <p>
                {{ formatDate(cartCache.DateTimeBusinessStart) }},
                {{ cartCache.BusinessHourStart }}
              </p>
            </div>
            <div>
              <strong>Terugbrengdatum</strong>
              <p>
                {{ formatDate(cartCache.DateTimeBusinessEnd) }},
                {{ cartCache.BusinessHourEnd }}
              </p>
            </div>
          </b-card>
          <div class="w-100">
            <router-link to="/winkelmandje" class="float-right pt-2"
              >Pas afhaallocatie en ontleendata aan ></router-link
            >
          </div>
          <confirmation-item-summary
            :items="items"
            :admin-costs="adminCosts"
            :cart-cache="cartCache"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="error" cols="12">
          <b-alert show variant="warning">
            <font-awesome-icon icon="exclamation-circle" />
            <span class="alert-message"> {{ error }} </span>
          </b-alert>
        </b-col>
      </b-row>

      <b-card
        v-if="items.length > 0"
        class="mb-4 mt-2"
        :class="{ 'btn-disabled': checkoutLoading }"
      >
        <b-button
          class=" btn"
          variant="primary"
          type="submit"
          :disabled="checkoutLoading"
        >
          Aanvraag bevestigen
          <font-awesome-icon
            v-if="checkoutLoading"
            class="fa-spin"
            far
            icon="spinner"
          />
        </b-button>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import calendar from 'vue2-slot-calendar/lib/calendar'
import { getCart, checkoutWithFiles } from '@/services/CartService'
import dayjs from 'dayjs'
import Breadcrumbs from '@/components/header/BreadCrumbs'

import Vue from 'vue'
import ConfirmationBuyerDetailsVue from '../../components/confirmation/ConfirmationBuyerDetails.vue'
import ConfirmationItemSummaryVue from '../../components/confirmation/ConfirmationItemSummary.vue'

export default {
  components: {
    Breadcrumbs,
    calendar,
    'confirmation-buyer-details': ConfirmationBuyerDetailsVue,
    'confirmation-item-summary': ConfirmationItemSummaryVue
  },
  data() {
    return {
      error: null,
      startDate: '',
      endDate: '',
      disabled: [0, 6],
      position: 'left',
      format: 'dd/MM/yyyy',
      clear: false,
      lang: 'nl',
      placeholder: 'Selecteer een datum',
      bus: new Vue(),
      checkoutLoading: false,
      loading: true,
      orderer: {
        firstname: '',
        lastname: '',
        street: '',
        houseNumber: '',
        postal: '',
        city: '',
        email: '',
        tel: ''
      },
      eventStartHourOptions: [
        { value: '1:00', text: '1:00' },
        { value: '2:00', text: '2:00' },
        { value: '3:00', text: '3:00' },
        { value: '4:00', text: '4:00' },
        { value: '5:00', text: '5:00' },
        { value: '6:00', text: '6:00' },
        { value: '7:00', text: '7:00' },
        { value: '8:00', text: '8:00' },
        { value: '9:00', text: '9:00' },
        { value: '10:00', text: '10:00' },
        { value: '11:00', text: '11:00' },
        { value: '12:00', text: '12:00' },
        { value: '13:00', text: '13:00' },
        { value: '14:00', text: '14:00' },
        { value: '15:00', text: '15:00' },
        { value: '16:00', text: '16:00' },
        { value: '17:00', text: '17:00' },
        { value: '18:00', text: '18:00' },
        { value: '19:00', text: '19:00' },
        { value: '20:00', text: '20:00' },
        { value: '21:00', text: '21:00' },
        { value: '22:00', text: '22:00' },
        { value: '23:00', text: '23:00' },
        { value: '00:00', text: '00:00' }
      ],
      reserverationDetails: {
        eventDescription: '',
        eventStartDate: '',
        eventStartHour: '',
        eventAdres: '',
        expectedRise: '',
        file: null,
        extraInfo: ''
      },
      items: [],
      retrievalDate: '',
      returnDate: '',
      warehouse: ''
    }
  },
  metaInfo: {
    title: 'Materiaal aanvragen',
    titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
  },
  computed: {
    expectedRise: function() {
      return this.reserverationDetails.expectedRise
    },

    adminCosts: function() {
      return this.cartCache.CartItems.Collection.find(
        cartItem => cartItem.ItemID === 'ADMIN'
      )
    },
    paAttestRequired: function() {
      return (
        this.$store.getters.cartCache.RequiredTestimonies.filter(
          testimony => testimony.TestimonyNumber === 'PA Attest'
        ).length > 0
      )
    },
    riscoAnalyseRequired: function() {
      return (
        this.$store.getters.cartCache.RequiredTestimonies.filter(
          testimony => testimony.TestimonyNumber === 'Risico analyse'
        ).length > 0
      )
    },
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    },
    user: function() {
      return this.$store.getters.user
    },
    account: function() {
      return this.$store.getters.account
    }
  },
  watch: {
    'reserverationDetails.eventDescription': function(val) {
      this.reserverationDetails.eventDescription = val.replace(/[<>]/g, '')
    },
    'reserverationDetails.extraInfo': function(val) {
      this.reserverationDetails.extraInfo = val.replace(/[<>]/g, '')
    }
  },

  async created() {
    const cart = await getCart()
    this.items = cart.filter(function(obj) {
      return obj.item !== 'Administratiekosten'
    })

    this.retrievalDate = dayjs(
      this.$store.getters.cartCache.DateTimeBusinessStart
    ).format('DD/MM/YYYY')
    this.returnDate = dayjs(
      this.$store.getters.cartCache.DateTimeBusinessEnd
    ).format('DD/MM/YYYY')
    this.warehouse = this.cartCache.WarehouseID

    this.orderer.houseNumber = this.account.CUST_HouseNumber
    this.orderer.city = this.account.CUST_City
    this.orderer.postal = this.account.CUST_ZipCode
    this.orderer.email = this.account.Email
    this.orderer.firstname = this.account.FirstName
    this.orderer.lastname = this.account.LastName
    this.orderer.tel = this.account.MobilePhone
    this.orderer.street = this.account.CUST_Street
    this.loading = false
  },
  methods: {
    handleChange({ value, name, type }) {
      if (type === 'number') {
        this.reserverationDetails[name] = parseInt(value)
      }
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    },
    replaceSpecialCharacters: function(input) {
      let inputElement = window.document.getElementById(input)
      let matchedValue = inputElement.value.match(/^[^<>]+/g)

      if (matchedValue != null) {
        matchedValue = matchedValue.join('')
      } else {
        matchedValue = ''
      }

      inputElement.value = matchedValue
    },
    chooseFiles: function() {
      document.getElementById('chooseFiles').click()
    },
    formatterToNumber(value) {
      if (isNaN(value)) {
        return value.replace(/\D/g, '')
      }
      return value
    },
    checkout: async function(event) {
      event.preventDefault()
      this.error = null
      this.checkoutLoading = true
      const validEventDate = dayjs(
        this.reserverationDetails.eventStartDate,
        'DD-MM-YYYY'
      ).isValid()
      let formData = new FormData()

      if (!validEventDate) {
        this.error =
          'Selecteer een geldige waarde voor de startdatum van het evenement.'
        this.checkoutLoading = false
        return
      }

      formData.append(
        'CUST_EventDescription',
        this.reserverationDetails.eventDescription
      )
      formData.append(
        'CUST_EventDate',
        dayjs(this.reserverationDetails.eventStartDate, 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        )
      )
      formData.append(
        'CUST_EventTimePeriod',
        this.reserverationDetails.eventStartHour
      )
      formData.append('CUST_EventAddress', this.reserverationDetails.eventAdres)
      formData.append(
        'CUST_EventExpectedParticipants',
        this.reserverationDetails.expectedRise
      )
      formData.append('CUST_Notes', this.reserverationDetails.extraInfo)
      if (this.reserverationDetails.file) {
        formData.append(
          this.reserverationDetails.file.name,
          this.reserverationDetails.file
        )
        formData.append('CUST_FileType', 'Testimony')
        formData.append('CUST_TestimonyNumber', 'PA Attest')
      }

      const reservation = await checkoutWithFiles({
        formData
      })
      this.$store.commit('setLatestReservation', {
        reservation
      })
      this.$store.commit('unsetRetrievalTime')
      this.$store.commit('unsetReturnTime')
      this.items = await getCart()

      this.$router.push({
        name: 'reservation-confirmation',
        props: { fields: this.fields, reservation }
      })

      this.checkoutLoading = false
    }
  }
}
</script>
<style>
.btn-disabled {
  pointer-events: none !important;
  cursor: default;
}
</style>
